
import { Vue, Component, Prop } from "vue-property-decorator";

import PropertyItem from "@/components/PropertyItem.vue";
import PropertyGraph from "@/components/PropertyGraph.vue";
import { DecoratedAsset, DecoratedProperty, AssetPropertySelection } from "@/types";
import { getProperty } from "@/config/asset";

@Component({
  components: {
    PropertyItem,
    PropertyGraph
  }
})
export default class DeviceDashboardTab extends Vue {
  @Prop({ type: Object, required: true })
  readonly asset: DecoratedAsset;

  @Prop({ type: Array, required: true })
  readonly itemProperties: string[];

  @Prop({ type: Array, required: true })
  readonly graphProperties: string[];

  get propertiesForItems(): DecoratedProperty[] {
    return this.itemProperties.map(n => getProperty(this.asset, n));
  }

  get propertiesForGraphs(): AssetPropertySelection[] {
    return this.graphProperties.map(n => ({ asset: this.asset, property: getProperty(this.asset, n) }));
  }
}
